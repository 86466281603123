body
{
    
    /* display:flex; */
    /* height: 100vh; */
    /* border: 2px solid black; */
    /* justify-content: center; */
    /* align-items: center; */
}
.container-login
{
    display:flex;
    /* max-width:80%;  */
    /* margin: 250px auto; */
    padding: 40px;
    border: 2px solid black;
    flex-direction: column;
}
.login-buttons
{
    margin: 20px;
}